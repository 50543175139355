.btn-close {
  background-color: #FF3030;
  border: none;
  color: white;
  padding: 5px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0.50rem;
}
.btn-pdf {
  background-color: #212529;
  border: none;
  color: white;
  padding: 5px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0.50rem;
}
.btn-pdf:hover {
  background-color: #212529;
  color: white;
}
.btn-pdf1 {
  background-color: #0d6efd;
  border: none;
  color: white;
  padding: 5px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0.50rem;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn-edit {
  background-color: #009826;
  border: none;
  color: white;
  padding: 5px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0.50rem;
}
.btn-create {
  background-color: #6A5ACD;
  border: none;
  color: white;
  padding: 5px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0.50rem;
}
.text-purple {
  color: #ffa857 !important;
}
.bg-headtable {
  background-color: #333366 !important;
}
.bg-navbg {
  background-color: #393939 !important;
}
.bg-rowstable {
  background-color: #595959 !important;
}
.bg-incard {
  background-color: #363c42 !important;
}
.bg-cardheader {
  background-color: #212529 !important;
}
.bg-reportheader {
  background-color: #cfcfcf !important;
}
.bg-reportcard {
  background-color: #fff !important;
}

.swal2-modal,.swal2-toast {
  background-color: #363c42 !important;
}
.swal2-popup {
  color: #eaeaea !important;
}

@media (max-width: 900px){
  .modal-dialog {
      max-width: 750px;
      margin: 1.75rem auto;
  }
}
